<template>
  <div class="zindex800" uk-sticky="end:#tostiky-panel;offset:80">
    <div class="bloc bloc_1">
        <div class="uk-grid-small" uk-grid>
            <div class="uk-width-1-2 uk-flex uk-flex-column uk-flex-middle">
                <h2>Commande <span v-if="commandeEnCours.numero_commande" >N° {{commandeEnCours.numero_commande}}</span></h2>
                <picture class="imgbalise">
                  <source  srcset="../assets/img/balise-flat-sfr-1.webp" type="image/webp">
                  <img src="../assets/img/balise-flat-sfr-1.png" />
                </picture>
            </div>
            <div class="uk-width-1-2 uk-flex uk-flex-column uk-flex-center">
                <p class="price nomargin"><span>Total TTC</span> <br> <span v-if="!commandeEnCours.numero_commande">{{nbProduct*productPrice}} €</span><span v-if="commandeEnCours.numero_commande">{{commandeEnCours.qte*productPrice}} €</span></p>
                <p class="etat"><span>Etat</span> <br> <span>{{commandeEnCours.etat}}</span> </p>
            </div>
        </div>
    </div>

    <div class="bloc bloc_2">
        <div class="uk-width-1-1">
           <h2>Détails de la commande</h2>
        </div>
        <div class="details_commande uk-grid-divider uk-grid-small"  uk-grid>
            <p class="uk-width-auto uk-flex uk-flex-column uk-flex-center">Nbre(s) <span class="gr-texte-black" v-if="!commandeEnCours.numero_commande">{{nbProduct}}</span><span class="gr-texte-black" v-if="commandeEnCours.numero_commande">{{commandeEnCours.qte}}</span></p>
            <p class="uk-width-expand uk-flex uk-flex-column uk-flex-center">Article(s) <span class="gr-texte-black">Balise GPS CAPTURS</span></p>
            <p class="uk-width-expand uk-flex uk-flex-column uk-flex-bottom">Prix Unitaire TTC(€) <span class="gr-texte-black">{{productPrice}}*</span></p>
        </div>
    </div>
    <!--<div class="bloc bloc_4">
        <div class="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-width-1-1">
            <button class="uk-button cta-sfr-primary">VALIDER LA COMMANDE</button>
            <p class="gr-texte-black">J'ANNULE MA COMMANDE</p>
        </div>
    </div>-->
    <div class="bloc bloc_5">
        <p>Durée de location : 5 jours.<br/><br/>
La balise GPS CAPTURS est offerte à la location pour les coureurs de la
Diagonale des Fous jusqu’au 30 septembre 2024, dans la limite des stocks
disponibles. Passé cette date, la location sera facturée à 3€. Visuel non
contractuel.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex'
export default {
  name: 'PanelRecapitulatif',
  components: {
  },
  props: {
    msg: String,
    idCommande:String,
  },
  data() {
    return {
      commandeEnCours:{
        numero_commande:"",
        etat:"En attente de paiement",
        qte:0,
      }

    }

  },
  mounted(){
    //console.log("this.idCommande:",this.idCommande);
    if(this.authenticated&&this.tokenAuth!=""&&this.idCommande!=""){
      console.log('ici');
      //axios.get(this.$store.state.urlws+'detailCommande/'+this.codesession+'/'+this.idCommande,{headers:{Authorization:'Bearer '+this.tokenAuth}})
      //axios.get(this.$store.state.urlws+'detailCommande?codesession='+this.codesession+'&commandeId='+this.idCommande,{headers:{Authorization:'Bearer '+this.tokenAuth}})
      axios.post(this.$store.state.urlws+'detailCommande',{'codesession': this.codesession,'commandeId':this.idCommande},{headers:{Authorization:'Bearer '+this.tokenAuth+''}})
      .then(response => {
        this.commandeEnCours.numero_commande=response.data.detailCommande.infoC.numero_commande;
        if(response.data.detailCommande.infoC.etat=="temporaire"){
          //this.commandeEnCours.etat="Réservé"; 
          this.commandeEnCours.etat="En attente de paiement";
        }
        else{
          this.commandeEnCours.etat=response.data.detailCommande.infoC.etat;
        }
        this.commandeEnCours.qte=response.data.detailCommande.articles.qte;
        this.commandeEnCours.prix=response.data.detailCommande.articles.prixArticle;

      })
      .catch(e => {
        //this.$store.commit('deconnexion',{});

        //this.$router.push({ name: 'commande' });
      })
    }
  },
  computed:{
      nbProduct () {
        return this.$store.state.panier.nb
      },
      productPrice () {
        return this.$store.state.productPrice
      },
      authenticated () {
        return this.$store.state.authenticated
      },
      tokenAuth () {
        return this.$store.state.tokenAuth
      },
      codesession () {
        return this.$store.state.codesession
      }
  }
}
</script>
