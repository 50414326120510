<template>

  <div id="gr_step_component">
      <div class="circle circle-active circle-active-step-1 active"><span class="active-number-step-1">1</span></div>
      <div class="divider"></div>
      <div class="circle circle-active">2</div>
      <div class="divider"></div>
      <div class="circle circle-active">3</div>
      <div class="divider"></div>
      <div class="circle circle-active">4</div>
  </div>
  <!-- FIN STEP COMPONENT -->

  <h1 class="gr-texte-black uk-margin-small-bottom">MERCI DE CONFIRMER QUE VOUS ÊTES BIEN UN COUREUR DE LA DIAGONALE DES FOUS</h1>

  <div>
    <form @submit.prevent="verifierCoureur" class="uk-form-stacked uk-flex uk-flex-top uk-width-1-1 uk-width-3-4@m uk-grid-small" uk-grid>

      <div class="uk-width-1-1">
          <label class="uk-form-label" for="form-stacked-nom">Nom*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="nom" id="nom" required type="text" placeholder="Votre nom">
          </div>
      </div>

      <div class="uk-width-1-1">
          <label class="uk-form-label" for="form-stacked-nom">Prénom*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="prenom" id="prenom" required type="text" placeholder="Votre prénom">
          </div>
      </div>

      <div class="uk-width-1-1">
          <label class="uk-form-label" for="form-stacked-nom">Numéro de dossard*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="dossard" id="dossard" required type="text" placeholder="Votre numéro de dossard">
          </div>
      </div>

      <div class="uk-width-1-1">
      	<button type="submit" class="uk-button cta-sfr-primary">Vérifier</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nom: "",
      prenom: "",
      dossard: "",
      coureurs: []
    };
  },
  mounted() {
    // Charger les données du fichier JSON en utilisant require
    const coureurs = require('@/assets/coureurs.json');
    this.coureurs = coureurs;
  },
  methods: {
    verifierCoureur() {
      const coureur = this.coureurs.find(c =>
        c.nom.toLowerCase() === this.nom.toLowerCase() &&
        c.prenom.toLowerCase() === this.prenom.toLowerCase() &&
        c.numero_de_dossard === this.dossard
      );



      if (coureur) {
        if (coureur.course.toLowerCase() === 'grr') {
          // Rediriger vers la page commande
          this.$store.commit('gotoStep',{stepCommande:1});
        } else {
          // Rediriger vers la page commande-non-disponible
          this.$router.push('/commande-non-disponible');
        }
      } else {
        alert('Coureur non trouvé. Veuillez vérifier vos nom, prenom et numero de dossard.');
      }
    }
  }
};
</script>
