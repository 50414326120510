<template>
  <div id="gr_step_component">
      <div class="circle circle-active-step-1 "><span class="active-number-step-1">1</span></div>
      <div class="divider active-divider-1"></div>
      <div class="circle circle-active circle-active-step-2 active"><span class="active-number-step-1">2</span></div>
      <div class="divider"></div>
      <div class="circle circle-active">3</div>
      <div class="divider"></div>
      <div class="circle circle-active">4</div>
  </div>
  <!-- FIN STEP COMPONENT -->

  <h1 class="gr-texte-black uk-margin-small-bottom">VOTRE COMMANDE</h1>

  <div class="uk-padding-small" uk-grid>
      <div class="uk-flex uk-flex-right uk-width-1-1 uk-hidden@m">
          <p class="price nomargin"><span>Total TTC</span> <br> <span>{{ nbProduct*productPrice }} €<sup class="uk-hidden@s">*</sup></span></p>
      </div>
     <div class="uk-width-1-1 uk-width-1-2@m uk-grid-collapse" uk-grid>
         <h2 class="gr-texte-black uk-width-1-1">Récapitulatif de la commande</h2>
         <div class="uk-width-1-3 uk-width-1-2@s uk-flex uk-flex-center">
           <picture>
             <source  srcset="../assets/img/balise-flat-sfr-1.webp" type="image/webp">
             <img class="image_balise" src="../assets/img/balise-flat-sfr-1.png" />
           </picture>
         </div>
         <div class="uk-width-2-3 uk-width-1-2@s uk-flex uk-flex-middle uk-flex-column">
             <h3 class="gr-texte-black">BALISE GPS CAPTURS</h3>
             <ul class="gr-texte-black nomargin">
                 <li>Autonomie : 1 mois</li>
                 <li>Pochette étanche</li>
                 <li>Poids : 60g</li>
                 <li>Export des données</li>
                 <li>Taille : 68x42x24 mm</li>
             </ul>
         </div>
     </div>


      <div class="uk-flex uk-flex-column uk-width-1-1 uk-width-1-2@m" uk-grid>

          <div class="uk-flex uk-flex-center uk-flex-right@s uk-width-1-1 uk-visible@m">
              <p class="price nomargin"><span>Total TTC</span> <br> <span>{{ nbProduct*productPrice }} €</span></p>
          </div>

          <div class="uk-grid-divider uk-flex uk-flex-right nomargin uk-width-1-1" uk-grid>
              <div class="uk-width-1-1 uk-width-auto@m nopadding">
                  <p class="nomargin uk-text-center">Nbre(s)</p>
                  <div class="uk-flex uk-flex-center uk-flex-middle">
                     
                      <strong class="gr-texte-black counter">{{ nbProduct }}</strong>
                      
                  </div>
              </div>
              <div class="uk-width-auto uk-flex uk-flex-middle uk-visible@m"><p class="uk-flex uk-flex-column uk-flex-center">Prix Unitaire TTC(€) <strong class=" gr-texte-black">{{ productPrice }}*</strong></p></div>
          </div>

          <div class="uk-flex uk-flex-center uk-flex-right@m uk-visible@s"><button @click="gotonext()" class="uk-button cta-sfr-primary" id="btn-valider-step1">valider la commande</button></div>
 
        
      </div>

      <p class="gr-mention-3 gr-texte-red gr-texte-16"><strong>Pour valider votre commande une empreinte bancaire de 1€ vous sera demandée après l’étape 4. Cette somme ne sera pas prélevée.</strong><br/><br/>La balise GPS sera à récupérer le 16/10/2024 à Saint-Pierre lors de la remise des dossards sur le stand SFR sur présentation de votre numéro de commande.</p>
  </div>







</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'PanelCommandeStep1',
  components: {
  },
  methods:{
    gotonext(){
      this.$store.commit('gotoStep',{stepCommande:2});
    },
    addProduct(){
      this.$store.commit('addProduct',{});
    },
    removeProduct(){
      this.$store.commit('removeProduct',{});
    },
  },
  computed: {
    stepCommande () {
      return this.$store.state.stepCommande
    },
    nbProduct () {
      return this.$store.state.panier.nb
    },
    productPrice () {
      return this.$store.state.productPrice
    }
  },
  props: {
    msg: String
  }
}
</script>
