<template>
  <div class="gr-main-container">
    <section class="mentions">
      <div class="uk-container">
        
      <h2>Choisissez votre profil coureur</h2>

      <div class="gr-cta-principaux">
              <router-link :to="'/commande'" :class="'uk-button cta-sfr-primary uk-visible@s'">Je suis coureur de la<br/>Diagonale des Fous</router-link><br/>
              <p class="gr-linker">
                <a class="uk-button cta-sfr-primary" target="_self" href="/#commande-non-disponible">Je suis coureur d’une autre course<br/> ou je veux suivre un coureur</a>
              </p>
              </div>

      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ChoixCoureur',
  props: {
    msg: String
  }
}
</script>
